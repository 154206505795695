<template>
	
    <div class="settings-card">
		
		<h1 class="settings__heading">Account</h1>
    
		<div class="settings">
			<input class="settings__input" type="text" value="" placeholder="Vendor name">
			<input class="settings__input" type="text" value="" placeholder="Vendor name">
			<input class="settings__input" type="text" value="" placeholder="Vendor name">
			<textarea class="settings__description" name="about" placeholder="about" rows="10" ></textarea>
		</div>
		<div class="settings__button-container"><button class="settings__button">save</button></div>
	</div>
</template>

